<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- facture detail -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="3508"
        :filename="NumVente ? NumVente : 'Facture-client'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="95%"

        ref="html2Pdf"
      >
        <section class="text-xs" style="padding-left: 55px;padding-top: 10px;font-size: 10px;" slot="pdf-content">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="vx-row" style="margin-top: 20px">
            <div class="vx-col w-1/2">

            </div>
            <div class="vx-col w-1/2 text-center text-xs font-bold">
              {{lieu_edition}} {{(DateVente ? DateVente : null) | moment("calendar", "July 10 2011") }}
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-1/2">
              <span class="font-bold text-xs">{{NomAssurance ? NomAssurance : ''}}</span>
            </div>
            <div class="vx-col w-1/2 text-center">
              <span class="font-bold text-xs">{{NomSociete ? NomSociete : ''}}</span>
            </div>
          </div>
          <div class="vx-row p-5">
            <div class="vx-col w-full text-center">
              <span class="text-xs">DETAIL FACTURE</span> <br>
              PERIODE DU  {{DateDebut | moment("calendar", "July 10 2011") }} AU {{DateFin | moment("calendar", "July 10 2011")}}
            </div>
            <table class="w-full mt-5 text-xs" cellpadding="0" cellspacing="0">
              <thead>
                <template>
                  <tr>
                    <th class="customth text-center text-xs" style="border-bottom-width: 0px; padding: 3px;" colspan="12">
                      CLIENT
                    </th>
                  </tr>
                  <tr>
                    <td class="customtd text-xs" style="border-bottom-width: 0px; border-right-width: 0px;"  colspan="1">
                      <span class="font-bold" style="font-size: 10px;">N°</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">DATE</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">MATRICULE</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">ENTREPRISE</span>
                    </td>
                    <td  class="customtd text-center" style="border-bottom-width: 0px;" colspan="6">
                      <span class="font-bold" style="font-size: 10px;">PATIENT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 10px;">{{NumVente ? NumVente : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 10px;">{{(DateVente ? DateVente : null)  | moment("calendar", "July 10 2011")}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-semibold" style="font-size: 10px;">{{matricule ? matricule : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-semibold" style="font-size: 10px;">{{NomSociete ? NomSociete : ''}}</span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="6">
                      <span class="font-semibold" style="font-size: 10px;">{{patient ? patient : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-bold" style="font-size: 10px;">VERRES OPTIQUES</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 10px;">FOURNISSEUR: {{NomfourVerre ? NomfourVerre : ''}} </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="3">
                      <span class="font-semibold" style="font-size: 10px;">NOM COMMERCIAL: {{NomCommercialVerre ? NomCommercialVerre : ''}} </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="4">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-semibold" style="font-size: 10px;">PRESCRIPTION</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;border-top-width: 0px;" colspan="1">

                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">SPHERE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">CYLINDRE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">AXE </span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">ADDITION </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 10px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">OD</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{SphVD ? SphVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{CylVD ? CylVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{AxeVD ? AxeVD : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{AddVD ? AddVD : ''}}</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 10px;">{{PrixVerD ? moneyFormatter(PrixVerD) : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">OG</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{SphVG ? SphVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{CylVG ? CylVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{AxeVG ? AxeVG : ''}}</span>
                    </td>
                    <td class="customtd" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                      <span class="font-bold" style="font-size: 10px;">{{AddVG ? AddVG : ''}}</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 10px;">{{PrixVerG ? moneyFormatter(PrixVerG) : ''}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" style="border-bottom-width: 0px; padding:10px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 10px;">TRAITEMENT DES VERRES </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="4">
                      <span class="font-semibold" style="font-size: 10px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">PHOTOGRAY</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixPhotogray === 0">
                        -
                      </template>
                      <template v-else style="font-size: 10px;">
                        {{moneyFormatter(prixPhotogray)}}
                      </template>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">ANTI REFLET</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAntiReflet === 0">
                          -
                        </template>
                        <template v-else style="font-size: 10px;">
                          {{moneyFormatter(prixAntiReflet)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">ANTI RAYURE</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAntiRayure === 0">
                          -
                        </template>
                        <template v-else style="font-size: 10px;">
                          {{moneyFormatter(prixAntiRayure)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">AUTRE</span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold">
                        <template v-if="prixAutreTraitement === 0">
                          -
                        </template>
                        <template v-else style="font-size: 10px;">
                          {{moneyFormatter(prixAutreTraitement)}}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 10px;">MONTANT VERRES </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">{{moneyFormatter(totalVerreProposition((PrixVerD ? PrixVerD : 0), (PrixVerG ? PrixVerG : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="12">
                      <span class="font-bold" style="font-size: 10px;">MONTURE</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                      <span class="font-bold" style="font-size: 10px;">FOURNISSEUR: {{NomfourMonture ? NomfourMonture : null}} </span>
                    </td>
                    <td class="customtd text-center" style="border-bottom-width: 0px;" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">PRIX HT</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-right-width: 0px;" colspan="5">

                      <span class="font-semibold" style="font-size: 10px;">MARQUE: {{marqueMont ? marqueMont : ''}}</span><br>
                      <span class="font-semibold" style="font-size: 10px;">REFERENCE: {{refMont ? refMont : ''}} </span><br>
                      <span class="font-semibold" style="font-size: 10px;">COULEUR: {{couleurMont ? couleurMont : ''}}</span><br>
                      <span class="font-semibold" style="font-size: 10px;">TAILLE: {{tailleMont ? tailleMont : ''}}</span><br>
                    </td>
                    <td class="customtd text-right" colspan="5">
                      <span class="font-semibold" style="font-size: 10px;">{{moneyFormatter((PrixMont ? PrixMont : 0))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">TOTAL HT: </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">{{moneyFormatter(totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;border-left-width: 0px;"  colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px; border-left-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">TICKET MODERATEUR </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">{{moneyFormatter(calculParClient((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (PlafForf ? PlafForf : 0), (TickForf ? TickForf : 20), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                    </td>
                  </tr>

                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px;border-right-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">PART ASSURANCE TTC </span>
                    </td>
                    <td class="customtd text-right" style="border-bottom-width: 0px;" colspan="2">
                      <span class="font-bold" style="font-size: 10px;">{{moneyFormatter(calculParAssurance((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (PlafForf ? PlafForf : 0), (TickForf ? TickForf : 20), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)))}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                    </td>
                    <td class="customtd text-left" style="border-bottom-width: 0px;border-left-width: 0px; padding: 10px;border-right-width: 0px;" colspan="4">
                      <span></span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd" colspan="12">
                      <span class="font-bold" style="font-size: 10px;">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(calculParAssurance((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (PlafForf ? PlafForf : 0), (TickForf ? TickForf : 20), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)))}}  FRANCS CFA</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="customtd text-right" colspan="12" style="font-size: 10px; border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;">
                      <span class="font-bold">L'OPTICIEN DIRECTEUR</span>
                    </td>
                  </tr>
                </template>
              </thead>
            </table>
          </div>
        </section>
      </vue-html2pdf>
      <!-- fin facture detail -->
      <!-- facture grouper -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="3508"
        :filename="NumVente ? NumVente : 'Facture-assurance-grouper'"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="95%"

        ref="html2Pdf2"
      >
        <section class="text-xs" style="padding-left: 55px;padding-top: 10px;font-size: 10px;" slot="pdf-content">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-1/2">

                </div>
                <div class="vx-col w-1/2 text-center" style="font-size: 10px;">
                  {{lieu_edition}} {{DateEdition | moment("calendar", "July 10 2011") }}
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col w-1/2">
                  <span class="font-bold" style="font-size: 10px;">
                    {{assuranceId ? (getAssuranceById(assuranceId) ? getAssuranceById(assuranceId).Nom_Abrege : null) : null}}
                  </span>
                  <div>
                    {{assuranceId ? (getAssuranceById(assuranceId).im ? `N°IM: ${getAssuranceById(assuranceId).im}` : null) : null}}
                  </div>
                  <div>
                    {{assuranceId ? (getAssuranceById(assuranceId).Adresse.adresse ? `${getAssuranceById(assuranceId).Adresse.adresse}` : null) : null}}
                  </div>
                  <div>
                    {{assuranceId ? (getAssuranceById(assuranceId).codeClient ? `${getAssuranceById(assuranceId).codeClient}` : null) : null}}
                  </div>
                </div>
                <div class="vx-col w-1/2 text-center">
                  <span class="font-bold" style="font-size: 10px;">
                    {{societeId ? (getSocieteById(societeId) ? getSocieteById(societeId).NomSociete : null) : null}}
                  </span>
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col w-full text-center">
                  <span style="font-size: 10px;">DETAIL FACTURE</span> <br>
                  PERIODE DU  {{DateDebut | moment("calendar", "July 10 2011")}} AU {{DateFin | moment("calendar", "July 10 2011")}}
                </div>
              </div>
              <template>
                <table class="w-full mt-5" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th style="font-size: 10px;" v-if="champDate">DATE</th>
                      <th style="font-size: 10px;" v-if="champNumBon">N°BPC</th>
                      <th style="font-size: 10px;" v-if="champMatricule">MATRICULE</th>
                      <th style="font-size: 10px;" v-if="champNomPrenom">NOM & PRENOM</th>
                      <th style="font-size: 10px;" v-if="champAssurePrincipal">ASSURE(E) PRINCIPAL(E)</th>
                      <th style="font-size: 10px;" v-if="champBeneficiaire">BENEFICIAIRE</th>
                      <th style="font-size: 10px;" v-if="champMonture">MONTURE</th>
                      <th style="font-size: 10px;" v-if="champVerre">VERRES</th>
                      <th style="font-size: 10px;" v-if="champVerreMonture">VERRES & MONTURE</th>
                      <th style="font-size: 10px;" v-if="champRemise">REMISE</th>
                      <th style="font-size: 10px;" v-if="champTotal">TOTAL</th>
                      <th style="font-size: 10px;" v-if="champClient">CLIENT</th>
                      <th style="font-size: 10px;" v-if="champAssurance">ASSURANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="indextr" v-for="(tr, indextr) in factures">
                      <th v-if="champDate" style="border-width: 0px;font-size: 10px;" >{{DateEdition | moment("calendar", "July 10 2011")}}</th>
                      <th v-if="champNumBon" style="border-width: 0px;font-size: 10px;">{{tr.RefPEC}}</th>
                      <th v-if="champMatricule" style="border-width: 0px;font-size: 10px;">{{tr.matricule}}</th>
                      <th v-if="champNomPrenom" style="border-width: 0px;font-size: 10px;">{{tr.patient}}</th>
                      <th v-if="champAssurePrincipal" style="border-width: 0px;font-size: 10px;">{{tr.Assurer}}</th>
                      <th v-if="champBeneficiaire" style="border-width: 0px;font-size: 10px;">{{tr.patient}}</th>
                      <th v-if="champMonture" class="text-center" style="border-width: 0px;font-size: 10px;">{{moneyFormatter(tr.PrixMont ? tr.PrixMont : 0)}}</th>
                      <th v-if="champVerre" class="text-center" style="border-width: 0px;font-size: 10px;">{{moneyFormatter(((tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}</th>
                      <th v-if="champVerreMonture" class="text-center" style="border-width: 0px;font-size: 10px;">{{moneyFormatter(((tr.PrixMont ? tr.PrixMont : 0) + (tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}</th>
                      <th v-if="champRemise" class="text-center" style="border-width: 0px;font-size: 10px;">{{moneyFormatter(tr.RemiseCom ? tr.RemiseCom : 0)}}</th>
                      <th v-if="champTotal" class="text-center" style="border-width: 0px;font-size: 10px;">
                      {{moneyFormatter(totalNet(tr.PrixMont, tr.PrixVerG, tr.PrixVerD, tr.RemiseCom, tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement))}}
                      </th>
                      <th v-if="champClient" class="text-center" style="border-width: 0px;font-size: 10px;">
                        {{moneyFormatter(calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 20), (tr.prixPhotogray ? tr.prixPhotogray : 0), (tr.prixAntiReflet ? tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                      </th>
                      <th v-if="champAssurance" class="text-center" style="border-width: 0px;font-size: 10px;">
                      {{moneyFormatter(calculParAssurance((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 20), (tr.prixPhotogray ? tr.prixPhotogray : 0),(tr.prixAntiReflet ?  tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                      </th>
                    </tr>
                  </tbody>
                  <template v-if="factures.length < 10">
                    <br><br><br><br><br><br><br><br><br>
                    <br><br>
                  </template>
                  <template v-if="factures.length > 10">
                    <br><br><br><br><br><br>
                  </template>
                  <tfoot>
                    <tr>
                      <th style="font-size: 10px;" class="text-center">MONTANT TTC</th>
                      <th style="font-size: 10px;" v-if="champNumBon" class="text-center"></th>
                      <th style="font-size: 10px;" v-if="champMatricule" class="text-center"></th>
                      <th style="font-size: 10px;" v-if="champNomPrenom" class="text-center"></th>
                      <th style="font-size: 10px;" v-if="champAssurePrincipal" class="text-center"></th>
                      <th style="font-size: 10px;" v-if="champBeneficiaire" class="text-center"></th>
                      <th style="font-size: 10px;" v-if="champMonture" class="text-center">{{moneyFormatter(totalMonture)}}</th>
                      <th style="font-size: 10px;" v-if="champVerre" class="text-center">{{moneyFormatter(totalVerrePlusTraitement)}}</th>
                      <th style="font-size: 10px;" v-if="champVerreMonture" class="text-center">{{moneyFormatter(totalFacture)}}</th>
                      <th style="font-size: 10px;" v-if="champRemise" class="text-center">{{moneyFormatter(totalRemise)}}</th>
                      <th style="font-size: 10px;" v-if="champTotal" class="text-center">{{moneyFormatter(totalFacture)}}</th>
                      <th style="font-size: 10px;" v-if="champClient" class="text-center"> {{moneyFormatter(totalClient)}} </th>
                      <th style="font-size: 10px;" v-if="champAssurance" class="text-center">{{moneyFormatter(totalAssurance)}}</th>
                    </tr>
                  </tfoot>
                </table>
              </template>
              <template v-if="factures.length < 10">
                <br><br><br><br><br>
              </template>
              <template v-if="factures.length > 10">
                <br>
              </template>

              <template>
                <table class="w-full mt-10" cellpadding="0" cellspacing="0">
                  <tr>
                    <th colspan="12">
                      <span style="font-size: 10px;" class="font-bold">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(totalAssurance)}} FRANCS CFA</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="font-size: 10px;border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" class="text-right" colspan="12">
                      <span class="font-bold">L'OPTICIEN DIRECTEUR</span>
                    </th>
                  </tr>
                </table>
              </template>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <!-- fin facture grouper -->
      <vx-card>
        <div class="vx-row">
          <div class="vx-col">
            <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-save" @click="SaveFactureAssurance()">SAUVEGARDER</vs-button>
          </div>
          <div class="vx-col">
            <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-printer" @click="generateFactureAssurance()">APERÇU FACTURE</vs-button>
          </div>
        </div>
        <vs-divider/>
        <div class="vx-row">
          <div class="vx-col mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col mt-4">
            <vs-select label="Assurance"  autocomplete class="w-full" v-model="assuranceId" @change="OnSelectAssuranceChange(assuranceId)">
              <vs-select-item :key="index" :value="item._id" :text="`${item.Nom_Complet}`" v-for="(item,index) in assuranceData" />
            </vs-select>
          </div>
          <div class="vx-col mt-4">
            <vs-select label="Société"  autocomplete class="w-full" v-model="societeId">
              <vs-select-item :key="index" :value="item._id" :text="`${item.NomSociete}`" v-for="(item,index) in Societes" />
            </vs-select>
          </div>
          <div class="vx-col mt-10" v-if="!societeId">
            <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-search" @click="SearchFacture()">VALIDER</vs-button>
          </div>
          <div class="vx-col mt-10" v-else>
            <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-search" @click="SearchFactureByAssuranceAndSociete()">VALIDER</vs-button>
          </div>
          <div class="vx-col mt-10" v-if="societeId">
            <vs-button class="w-full" color="danger" icon-pack="feather" icon="icon-x-circle" @click="societeId=null">REINITIALISER</vs-button>
          </div>

        </div>

      </vx-card>
    </div>
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-popup title="TICKET MODERATEUR" :active.sync="popupInfoTicket">
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>Monture: {{moneyFormatter(tickModerateurMonture)}}</span>
              </div>
              <div class="vx-col w-full mt-5">
                <span>Verre: {{moneyFormatter(tickModerateurVerre)}}</span>
              </div>
            </div>
          </vs-popup>
          <vx-card class="mt-5">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-1/2">

                  </div>
                  <div class="vx-col w-1/2 text-center">
                    {{lieu_edition}} {{DateEdition | moment("calendar", "July 10 2011") }}
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                      <a class="flex items-center cursor-pointer">
                        <feather-icon icon="Edit3Icon" svgClasses="h-4 w-4 ml-1" />
                      </a>

                      <vs-dropdown-menu class="dropdown-login">
                        <div class="p-5" style="min-width: 30rem">
                          <div class="vx-row">
                            <div class="vx-col w-full">
                              <label for="">Lieu d'edition</label>
                              <vs-input class="w-full" type="text" name="lieu_edition" :value="lieu_edition" @change.native="lieu_edition=$event.target.value" />
                            </div>
                            <div class="vx-col w-full mt-5">
                              <label for="">Date d'edition</label>
                              <flat-pickr v-model="DateEdition" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateEdition" />
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-menu>

                    </vs-dropdown>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-1/2">
                    <span class="font-bold">
                      {{assuranceId ? (getAssuranceById(assuranceId) ? getAssuranceById(assuranceId).Nom_Abrege : null) : null}}
                    </span>
                    <div>
                      {{assuranceId ? (getAssuranceById(assuranceId).im ? `N°IM: ${getAssuranceById(assuranceId).im}` : null) : null}}
                    </div>
                    <div>
                      {{assuranceId ? (getAssuranceById(assuranceId).Adresse.adresse ? `${getAssuranceById(assuranceId).Adresse.adresse}` : null) : null}}
                    </div>
                    <div>
                      {{assuranceId ? (getAssuranceById(assuranceId).codeClient ? `${getAssuranceById(assuranceId).codeClient}` : null) : null}}
                    </div>
                  </div>
                  <div class="vx-col w-1/2 text-center">
                    <span class="font-bold">
                      {{societeId ? (getSocieteById(societeId) ? getSocieteById(societeId).NomSociete : null) : null}}
                    </span>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <span>DETAIL FACTURE</span> <br>
                    PERIODE DU  {{DateDebut | moment("calendar", "July 10 2011")}} AU {{DateFin | moment("calendar", "July 10 2011")}}
                  </div>
                </div>
                <template>
                  <table class="w-full mt-5" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th v-if="champDate">DATE</th>
                        <th v-if="champNumBon">N°BPC</th>
                        <th v-if="champMatricule">MATRICULE</th>
                        <th v-if="champNomPrenom">NOM & PRENOM</th>
                        <th v-if="champAssurePrincipal">ASSURE(E) PRINCIPAL(E)</th>
                        <th v-if="champBeneficiaire">BENEFICIAIRE</th>
                        <th v-if="champMonture">MONTURE</th>
                        <th v-if="champVerre">VERRES</th>
                        <th v-if="champVerreMonture">VERRES & MONTURE</th>
                        <th v-if="champRemise">REMISE</th>
                        <th v-if="champTotal">TOTAL</th>
                        <th v-if="champClient">CLIENT</th>
                        <th v-if="champAssurance">ASSURANCE</th>
                        <th style="border-width: 0px;">
                          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                            <a class="flex items-center cursor-pointer">
                              <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-plus"></vs-button>
                            </a>
                            <vs-dropdown-menu class="dropdown-login">
                              <div class="vx-row p-2" style="min-width: 40rem">
                                <div class="vx-row p-5">
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>Date</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch  v-model="champDate" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>N°BPC</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champNumBon" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>MATRICULE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champMatricule" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>NOM & PRENOM</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champNomPrenom" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>ASSURE(E) PRINCIPAL(E)</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champAssurePrincipal" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>BENEFICIAIRE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champBeneficiaire" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>MONTURE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champMonture" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>VERRES</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champVerre" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>VERRES+MONTURE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champVerreMonture" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>REMISE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champRemise" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>TOTAL</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champTotal" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>CLIENT</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champClient" />
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <span>ASSURANCE</span>
                                  </div>
                                  <div class="vx-col w-1/2 mt-5">
                                    <vs-switch v-model="champAssurance" />
                                  </div>
                                </div>
                                <div class="vx-row mt-5 p-5">
                                  <div class="vx-col">
                                    <vs-button class="w-full" color="primary" icon-pack="feather" icon="icon-file" @click="createFactureAssuranceModel()" >SAUVEGARDE LE MODELE</vs-button>
                                  </div>
                                </div>
                              </div>
                            </vs-dropdown-menu>

                          </vs-dropdown>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="indextr" v-for="(tr, indextr) in factures">
                        <td v-if="champDate" style="border-width: 0px;" >{{tr.DateVente | moment("calendar", "July 10 2011")}}</td>
                        <td v-if="champNumBon" style="border-width: 0px;">{{tr.RefPEC}}</td>
                        <td v-if="champMatricule" style="border-width: 0px;">{{tr.matricule}}</td>
                        <td v-if="champNomPrenom" style="border-width: 0px;">{{tr.patient}}</td>
                        <td v-if="champAssurePrincipal" style="border-width: 0px;">{{tr.Assurer}}</td>
                        <td v-if="champBeneficiaire" style="border-width: 0px;">{{tr.patient}}</td>
                        <td v-if="champMonture" class="text-center" style="border-width: 0px;">{{moneyFormatter(tr.PrixMont ? tr.PrixMont : 0)}}</td>
                        <td v-if="champVerre" class="text-center" style="border-width: 0px;">{{moneyFormatter(((tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}</td>
                        <td v-if="champVerreMonture" class="text-center" style="border-width: 0px;">{{moneyFormatter(((tr.PrixMont ? tr.PrixMont : 0) + (tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}</td>
                        <td v-if="champRemise" class="text-center" style="border-width: 0px;">{{moneyFormatter(tr.RemiseCom ? tr.RemiseCom : 0)}}</td>
                        <td v-if="champTotal" class="text-center" style="border-width: 0px;">
                        {{moneyFormatter(totalNet((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.prixPhotogray ? tr.prixPhotogray : 0), (tr.prixAntiReflet ? tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                        </td>
                        <td v-if="champClient" class="text-center hover:underline" style="border-width: 0px;" @click="showInfoTicketModerateur((tr.PrixMont ? tr.PrixMont : 0), ((tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)), calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 20), (tr.prixPhotogray ? tr.prixPhotogray : 0), (tr.prixAntiReflet ? tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)), totalNet((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.prixPhotogray ? tr.prixPhotogray : 0), (tr.prixAntiReflet ? tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))">
                          {{moneyFormatter(calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 20), (tr.prixPhotogray ? tr.prixPhotogray : 0), (tr.prixAntiReflet ? tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                        </td>
                        <td v-if="champAssurance" class="text-center" style="border-width: 0px;">
                        {{moneyFormatter(calculParAssurance((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 20), (tr.prixPhotogray ? tr.prixPhotogray : 0),(tr.prixAntiReflet ?  tr.prixAntiReflet : 0), (tr.prixAntiRayure ? tr.prixAntiRayure : 0), (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                      </td>
                        <td class="flex text-center" style="border-width: 0px;">
                          <vs-button color="primary" icon-pack="feather" icon="icon-printer" @click="generateReport(tr)" ></vs-button>
                          <vs-button class="ml-2" color="warning" icon-pack="feather" icon="icon-eye-off" @click="removeLine(tr._id)" ></vs-button>
                          <vs-button class="ml-2" color="danger" icon-pack="feather" icon="icon-trash-2" @click="confirmDeleteRecord(tr)" ></vs-button>
                        </td>
                        <td class="text-center" style="border-width: 0px;">
                        </td>
                      </tr>
                    </tbody>
                    <template v-if="factures.length < 10">
                      <br><br><br><br><br><br><br><br><br><br>
                    </template>
                    <tfoot>
                      <tr>
                        <th class="text-center">MONTANT TTC</th>
                        <th v-if="champNumBon" class="text-center"></th>
                        <th v-if="champMatricule" class="text-center"></th>
                        <th v-if="champNomPrenom" class="text-center"></th>
                        <th v-if="champAssurePrincipal" class="text-center"></th>
                        <th v-if="champBeneficiaire" class="text-center"></th>
                        <th v-if="champMonture" class="text-center">{{moneyFormatter(totalMonture)}}</th>
                        <th v-if="champVerre" class="text-center">{{moneyFormatter(totalVerrePlusTraitement)}}</th>
                        <th v-if="champVerreMonture" class="text-center">{{moneyFormatter(totalFacture)}}</th>
                        <th v-if="champRemise" class="text-center">{{moneyFormatter(totalRemise)}}</th>
                        <th v-if="champTotal" class="text-center">{{moneyFormatter(totalFacture)}}</th>
                        <th v-if="champClient" class="text-center"> {{moneyFormatter(totalClient)}} </th>
                        <th v-if="champAssurance" class="text-center">{{moneyFormatter(totalAssurance)}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </template>

                <template>
                  <table class="w-full mt-10" cellpadding="0" cellspacing="0">
                    <tr>
                      <th colspan="12">
                        <span class="font-bold">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(totalAssurance)}} FRANCS CFA</span>
                      </th>
                    </tr>
                  </table>
                </template>
              </div>
            </div>
          </vx-card>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    vSelect,
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      popupInfoTicket: false,
      tickModerateurMonture: 0,
      tickModerateurVerre: 0,
      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : null,
      lieu_edition: 'ABIDJAN LE',
      DateEdition: null,
      DateDebut: null,
      DateFin: null,
      assuranceId: null,
      societeId: null,
      champDate: true,
      champMatricule: true,
      champNomPrenom: true,
      champMonture: true,
      champVerre: true,
      champRemise: true,
      champTotal: true,
      champClient: true,
      champAssurance: true,
      champVerreMonture: true,
      champAssurePrincipal: true,
      champBeneficiaire: true,
      champNumBon: true,

      DateVente: null,
      AddVD: null,
      AddVG: null,
      AxeVD: null,
      AxeVG: null,
      CylVD: null,
      CylVG: null,
      IdAssurance: null,
      IdClient: null,
      IdMont: null,
      IdOfficine: null,
      IdSociete: null,
      IdfourMonture: null,
      IdfourVerre: null,
      Idprescripteur: null,
      NomAssurance: null,
      NomCommercialVerre: null,
      NomSociete: null,
      NomfourMonture: null,
      NomfourVerre: null,
      NumFact: null,
      NumPatient: null,
      NumPolice: null,
      NumVente: null,
      PlafForf: null,
      PrixMont: null,
      PrixVerD: null,
      PrixVerG: null,
      QteMont: null,
      QteVerD: null,
      QteVerG: null,
      RefPEC: null,
      RefVerD: null,
      RefVerG: null,
      RemiseCom: null,
      SphVD: null,
      SphVG: null,
      TickForf: null,
      couleurMont: null,
      marqueMont: null,
      matricule: null,
      patient: null,
      refMont: null,
      tailleMont: null,

      prixPhotogray: 0,
      prixAntiReflet: 0,
      prixAntiRayure: 0,
      prixAutreTraitement: 0,

      idFacture: null
    }
  },
  computed: {
    assuranceData () {
      return this.$store.state.assurance.assurances
    },
    Societes () {
      return this.$store.state.societe.societes
    },
    factures () {
      return this.$store.state.facturation.facture_assurances
    },
    totalAssurance () {
      const factures = this.$store.state.facturation.facture_assurances
      const totalAssu = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + (this.calculParAssurance(facture.PrixMont, facture.PrixVerG, facture.PrixVerD, facture.RemiseCom, facture.PlafForf, facture.TickForf, facture.prixPhotogray, facture.prixAntiReflet, facture.prixAntiRayure, facture.prixAutreTraitement))
      }, 0) : 0
      return totalAssu
    },
    totalClient () {
      const factures = this.$store.state.facturation.facture_assurances
      const totalClient = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + this.calculParClient(facture.PrixMont, facture.PrixVerG, facture.PrixVerD, facture.RemiseCom, facture.PlafForf, facture.TickForf, facture.prixPhotogray, facture.prixAntiReflet, facture.prixAntiRayure, facture.prixAutreTraitement)
      }, 0) : 0
      return totalClient
    },
    totalFacture () {
      const factures = this.$store.state.facturation.facture_assurances
      const total = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + this.totalNet(facture.PrixMont, facture.PrixVerG, facture.PrixVerD, facture.RemiseCom, facture.prixPhotogray, facture.prixAntiReflet, facture.prixAntiRayure, facture.prixAutreTraitement)
      }, 0) : 0
      return total
    },
    totalRemise () {
      const factures = this.$store.state.facturation.facture_assurances
      const total = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + (facture.RemiseCom ? facture.RemiseCom : 0)
      }, 0) : 0

      return total
    },
    totalMonture () {
      const factures = this.$store.state.facturation.facture_assurances
      const total = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + (facture.PrixMont ? facture.PrixMont : 0)
      }, 0) : 0

      return total
    },
    totalVerrePlusTraitement () {
      const factures = this.$store.state.facturation.facture_assurances
      const total = factures.length > 0 ? factures.reduce((acc, facture) => {
        return acc + ((facture.PrixVerG ? facture.PrixVerG : 0) + (facture.PrixVerD ? facture.PrixVerD : 0) + (facture.prixPhotogray ? facture.prixPhotogray : 0) + (facture.prixAntiReflet ? facture.prixAntiReflet : 0) + (facture.prixAntiRayure ? facture.prixAntiRayure : 0) + (facture.prixAutreTraitement ? facture.prixAutreTraitement : 0))
      }, 0) : 0

      return total
    }
    /* totalAssurance () {

    } */
  },
  methods: {
    montureTicket (totalMonture, ticketModerateur, totalVente) {
      const total = ((totalMonture ? +totalMonture : 0) * (ticketModerateur ? +ticketModerateur : 0)) / (totalVente ? totalVente : 1)
      return total
    },
    verreTicket (totalVerre, ticketModerateur, totalVente) {
      const total = ((totalVerre ? +totalVerre : 0) * (ticketModerateur ? +ticketModerateur : 0)) / (totalVente ? totalVente : 1)
      return total
    },
    showInfoTicketModerateur (monture, verre, tickmoderateur, totalVente) {
      this.tickModerateurMonture = this.montureTicket(monture, tickmoderateur, totalVente)
      this.tickModerateurVerre = this.verreTicket(verre, tickmoderateur, totalVente)
      this.popupInfoTicket = true
    },
    removeLine (id) {
      this.$store.getters['facturation/removeLineOnFactureAssurance'](id)
    },
    generateReport (data) {
      this.DateVente = data.DateVente ? data.DateVente : null
      this.AddVD = data.AddVD ? data.AddVD : null
      this.AddVG = data.AddVG ? data.AddVG : null
      this.AxeVD = data.AxeVD ? data.AxeVD : null
      this.AxeVG = data.AxeVG ? data.AxeVG : null
      this.CylVD = data.CylVD ? data.CylVD : null
      this.CylVG = data.CylVG ? data.CylVG : null
      this.IdAssurance = data.IdAssurance ? data.IdAssurance : null
      this.IdClient = data.IdClient ? data.IdClient : null
      this.IdMont = data.IdMont ? data.IdMont : null
      this.IdOfficine = data.IdOfficine ? data.IdOfficine : null
      this.IdSociete = data.IdSociete ? data.IdSociete : null
      this.IdUser = data.IdUser ? data.IdUser : null
      this.IdfourMonture = data.IdfourMonture ? data.IdfourMonture : null
      this.IdfourVerre = data.IdfourVerre ? data.IdfourVerre : null
      this.Idprescripteur = data.Idprescripteur ? data.Idprescripteur : null
      this.NomAssurance = data.NomAssurance ? data.NomAssurance : null
      this.NomCommercialVerre = data.NomCommercialVerre ? data.NomCommercialVerre : null
      this.NomSociete = data.NomSociete ? data.NomSociete : null
      this.NomfourMonture = data.NomfourMonture ? data.NomfourMonture : null
      this.NomfourVerre = data.NomfourVerre ? data.NomfourVerre : null
      this.NumFact = data.NumFact ? data.NumFact : null
      this.NumPrixVerG = data.NumPrixVerG ? data.NumPatient : null
      this.NumPolice = data.NumPolice ? data.NumPolice : null
      this.NumVente = data.NumVente ? data.NumVente : null
      this.PlafForf = data.PlafForf ? data.PlafForf : null
      this.PrixMont = data.PrixMont ? data.PrixMont : null
      this.PrixVerD = data.PrixVerD ? data.PrixVerD : null
      this.PrixVerG = data.PrixVerG ? data.PrixVerG : null
      this.QteMont = data.QteMont ? data.QteMont : null
      this.QteVerD = data.QteVerD ? data.QteVerD : null
      this.QteVerG = data.QteVerG ? data.QteVerG : null
      this.RefPEC = data.RefPEC ? data.RefPEC : null
      this.RefVerD = data.RefVerD ? data.RefVerD : null
      this.RefVerG = data.RefVerG ? data.RefVerG : null
      this.RemiseCom = data.RemiseCom ? data.RemiseCom : null
      this.SphVD = data.SphVD ? data.SphVD : null
      this.SphVG = data.SphVG ? data.SphVG : null
      this.TickForf = data.TickForf ? data.TickForf : null
      this.couleurMont = data.couleurMont ? data.couleurMont : null
      this.matricule = data.matricule ? data.matricule : null
      this.patient = data.patient ? data.patient : null
      this.refMont = data.refMont ? data.refMont : null
      this.tailleMont = data.tailleMont ? data.tailleMont : null
      this.prixPhotogray = data.prixPhotogray ? data.prixPhotogray : 0
      this.prixAntiReflet = data.prixAntiReflet ? data.prixAntiReflet : 0
      this.prixAntiRayure = data.prixAntiRayure ? data.prixAntiRayure : 0
      this.prixAutreTraitement = data.prixAutreTraitement ? data.prixAutreTraitement : 0

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    generateFactureAssurance () {
      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2Pdf2.generatePdf()

    },
    OnSelectAssuranceChange (id) {
      const modelFacture = this.$store.getters['facturation/getModeleFactureByAssurance'](id)
      //console.log('modelFacture:', modelFacture)
      this.champDate = modelFacture ? modelFacture.champDate : this.champDate
      this.champMatricule = modelFacture ? modelFacture.champMatricule : this.champMatricule
      this.champNomPrenom = modelFacture ? modelFacture.champNomPrenom : this.champNomPrenom
      this.champMonture = modelFacture ? modelFacture.champMonture : this.champMonture
      this.champVerre = modelFacture ? modelFacture.champVerre : this.champVerre
      this.champRemise = modelFacture ? modelFacture.champRemise : this.champRemise
      this.champTotal = modelFacture ? modelFacture.champTotal : this.champTotal
      this.champClient = modelFacture ? modelFacture.champClient : this.champClient
      this.champAssurance = modelFacture ? modelFacture.champAssurance : this.champAssurance
      this.champVerreMonture = modelFacture ? modelFacture.champVerreMonture : this.champVerreMonture
      this.champAssurePrincipal = modelFacture ? modelFacture.champAssurePrincipal : this.champAssurePrincipal
      this.champBeneficiaire = modelFacture ? modelFacture.champBeneficiaire : this.champBeneficiaire
      this.champNumBon = modelFacture ? modelFacture.champNumBon : this.champNumBon

    },
    getAssuranceById (id) {
      return this.$store.state.assurance.assurances ? this.$store.state.assurance.assurances.find((a) => a._id === id) : null
    },
    getSocieteById (id) {
      const societes = this.$store.state.societe.societes

      return societes ? societes.find((s) => s._id === id) : null
    },
    totalVerreProposition (prixVer1, prixVer2) {
      return (+prixVer1 + +prixVer2) + +this.prixPhotogray + +this.prixAntiReflet + +this.prixAntiRayure + +this.prixAutreTraitement
    },
    totalNet (PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      return ((((+PrixMont ? +PrixMont : 0) + (+PrixVerG ? +PrixVerG : 0) + (+PrixVerD ? +PrixVerD : 0) + (prixPhotogray ? +prixPhotogray : 0) + (prixAntiReflet ? +prixAntiReflet : 0) + (prixAntiRayure ? +prixAntiRayure : 0) + (prixAutreTraitement ? +prixAutreTraitement : 0)) - (RemiseCom ? RemiseCom : 0)))
    },
    calculParClient (PrixMont, PrixVerG, PrixVerD, RemiseCom, PlafForf, ticket, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let partClient = null

      if (this.totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)) >= PlafForf) {
        partClient = this.totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)) - +PlafForf
      } else if (ticket <= 100) {
        partClient = Math.round(((this.totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0)) * ticket) / 100))
      } else {

        partClient = ticket
      }

      return partClient
    },
    calculParAssurance (PrixMont, PrixVerG, PrixVerD, RemiseCom, PlafForf, ticket, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let PartAssu = null
      const factureNet = this.totalNet((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0))
      //par defaut le ticket moderateur = 20%
      const partClient = this.calculParClient((PrixMont ? PrixMont : 0), (PrixVerG ? PrixVerG : 0), (PrixVerD ? PrixVerD : 0), (RemiseCom ? RemiseCom : 0), (PlafForf ? PlafForf : 0), (ticket ? ticket : 20), (prixPhotogray ? prixPhotogray : 0), (prixAntiReflet ? prixAntiReflet : 0), (prixAntiRayure ? prixAntiRayure : 0), (prixAutreTraitement ? prixAutreTraitement : 0))

      if (factureNet >= PlafForf) {
        PartAssu = PlafForf
      } else {
        PartAssu = Math.round((+factureNet - +partClient))
      }
      return PartAssu
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    SearchFacture () {
      const payload = {
        dateDebut: new Date(this.DateDebut).toISOString().split('T')[0],
        dateFin: new Date(this.DateFin).toISOString().split('T')[0],
        IdAssurance: this.assuranceId
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/search', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    SearchFactureByAssuranceAndSociete () {
      //alert('Search Assurance')
      const payload = {
        dateDebut: new Date(this.DateDebut).toISOString().split('T')[0],
        dateFin: new Date(this.DateFin).toISOString().split('T')[0],
        IdAssurance: this.assuranceId,
        IdSociete: this.societeId
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/SearchFactureByAssuranceAndSociete', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    SaveFactureAssurance () {
      if (this.factures.length > 0) {
        const payload = {
          IdUser: this.IdUser,
          PeriodeDebut: new Date(this.DateDebut).toISOString().split('T')[0],
          PeriodeFin: new Date(this.DateFin).toISOString().split('T')[0],
          lieu_edition: this.lieu_edition,
          DateEdition: new Date(this.DateEdition).toISOString().split('T')[0],
          IdAssurance: this.assuranceId,
          NomAssurance: this.assuranceId ? (this.getAssuranceById(this.assuranceId) ? this.getAssuranceById(this.assuranceId).Nom_Abrege : null) : null,
          IdSociete: this.societeId,
          NomSociete: this.societeId ? (this.getSocieteById(this.societeId) ? this.getSocieteById(this.societeId).NomSociete : null) : null,
          MontantAssurance: this.totalAssurance,
          MontantClient: this.totalClient,
          MontantTotal: this.totalFacture,
          MontantVerre: this.totalVerrePlusTraitement,
          MontantMonture: this.totalMonture,
          MontantRemise: this.totalRemise,
          facturesClient: this.factures,
          champDate: this.champDate,
          champMatricule: this.champMatricule,
          champNomPrenom: this.champNomPrenom,
          champMonture: this.champMonture,
          champVerre: this.champVerre,
          champRemise: this.champRemise,
          champTotal: this.champTotal,
          champClient: this.champClient,
          champAssurance: this.champAssurance,
          champVerreMonture: this.champVerreMonture,
          champAssurePrincipal: this.champAssurePrincipal,
          champBeneficiaire: this.champBeneficiaire,
          champNumBon: this.champNumBon
        }
        this.$vs.loading({
          type: 'sound'
        })
        this.$store.dispatch('facturation/SaveFactureAssurance', payload)
          .then(() => {
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      } else {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: 'Error',
          text: 'Sauvegarde impossible, aucune facture trouvé',
          buttonAccept: 'true'
        })
      }
    },
    confirmDeleteRecord (data) {
      /* this.IdClient = data.IdClient
      this.NumVente = data.NumVente */
      this.idFacture = data._id

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: 'Cette action supprime le traitement de la facture',
        accept: this.removeVenteAgence,
        acceptText: 'Supprimer'
      })
    },
    removeVenteAgence () {
      const payload = {
        id: this.idFacture
        /* NumVente: this.NumVente,
        IdClient: this.IdClient */
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('facturation/removeVenteAgence', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          /* this.IdClient = null
          this.NumVente = null */
          this.idFacture = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    createFactureAssuranceModel () {
      const payload = {
        IdUser: this.IdUser,
        IdAssurance: this.assuranceId,
        champDate: this.champDate,
        champMatricule: this.champMatricule,
        champNomPrenom: this.champNomPrenom,
        champMonture: this.champMonture,
        champVerre: this.champVerre,
        champRemise: this.champRemise,
        champTotal: this.champTotal,
        champClient: this.champClient,
        champAssurance: this.champAssurance,
        champVerreMonture: this.champVerreMonture,
        champAssurePrincipal: this.champAssurePrincipal,
        champBeneficiaire: this.champBeneficiaire,
        champNumBon: this.champNumBon
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/createFactureAssuranceModel', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getFactureAssuranceModel () {
      this.$store.dispatch('facturation/getFactureAssuranceModel')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getAssurances()
    this.getSocietes()
    this.getFactureAssuranceModel()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 3px;
}
</style>

